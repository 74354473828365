import { ref } from "vue";
import {
  Plugins,
  CameraResultType,
  CameraSource,
  CameraPhoto,
} from "@capacitor/core";
import { isPlatform } from "@ionic/vue";

// firebase service
import firebaseService from "@/firebase-service";
import { PhotoPath } from "@/firebase-service";

export function usePhotoGallery() {
  const { Camera, Filesystem } = Plugins;
  const galleryPhotos = ref<PhotoPath[]>([]);

  const { savePhoto } = firebaseService();

  const convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  const savePicture = async (
    photo: CameraPhoto,
    fileName: string
  ): Promise<PhotoPath> => {
    let base64Data: string;
    // "hybrid" will detect Cordova or Capacitor;
    let path = "";
    if (isPlatform("hybrid")) {
      const file = await Filesystem.readFile({
        // eslint-disable-next-line
        path: photo.path!,
      });
      base64Data = file.data;
      // eslint-disable-next-line
      path += photo.path!
    } else {
      // Fetch the photo, read as a blob, then convert to base64 format
      // eslint-disable-next-line
      const response = await fetch(photo.webPath!);
      // eslint-disable-next-line
      path += photo.webPath!
      const blob = await response.blob();
      base64Data = (await convertBlobToBase64(blob)) as string;
    }

    return savePhoto(fileName, base64Data, path);
  };

  const takePhoto = async () => {
    const cameraPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt,
      quality: 100,
    });
    const fileName = new Date().getTime() + ".jpeg";
    try {
      const path = await savePicture(cameraPhoto, fileName);
      // console.log(path);
      galleryPhotos.value.push(path);
    } catch (e) {
      alert(e.message);
    }
  };

  /**
   *
   * @param photo
   */
  const deleteFromGallery = async (url: string) => {
    // Let's go looking for the url
    let foundIndex = -1;
    for (let index = 0; index < galleryPhotos.value.length; index++) {
      if (galleryPhotos.value[index].url == url) {
        foundIndex = index
        break;
      }
    }
    if (foundIndex > -1) {
      galleryPhotos.value.splice(foundIndex, 1);
    }
  };

  return {
    galleryPhotos,
    takePhoto,
    deleteFromGallery,
  };
}
