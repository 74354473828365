<template>
  <ion-img :src="photo.url" v-bind="$attrs"></ion-img>
  <div class="loading-image" v-if="photo.progress !== 100">
    <ion-spinner name="crescent"></ion-spinner>
    {{ photo.progress.toFixed(0) }} %
  </div>
</template>

<script lang="ts">
import { IonSpinner, IonImg } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoadableImage",
  emits: ["loading", "loaded"],
  props: {
    photo: { type: Object },
  },
  components: {
    IonImg,
    IonSpinner,
  },

  watch: {
    photo: {
      immediate: true,
      handler: function (updated) {
        this.$emit("loading");
        const emitFunction = this.$emit;
        updated.uploadTask.on(
          "state_changed",
          function (snapshot: any) {
            updated.progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log("Uploading:" + updated.progress);
          },
          function (error: any) {
            console.log(error);
          },
          function () {
            // console.log("Done");
            updated.uploadTask.snapshot.ref
              .getDownloadURL()
              .then((url: string) => {
                // console.log("Got URL:" + url + " Old ULR:" + updated.url);
                updated.url = url;
                updated.progress = 100;
                emitFunction("loaded");
              });
          }
        );
      },
    },
  },
});
</script>

<style scoped>
.loading-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
}
</style>