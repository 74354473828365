<template>
  <ion-page>
    <!-- <ion-loading :is-open="isOpenRef" message="Getting your location...">
    </ion-loading> -->
    <ion-content :fullscreen="true">
      <div class="logo-header">
        <ion-img src="/assets/logo.png"></ion-img>
        <h1>Give Your Suggestions!</h1>
        <p class="black">
          Inspire. Enhance. Critique. Praise.<br />Start by finding the place
        </p>
      </div>
      <div class="login-container">
        <h3>Find a Healthcare Provider</h3>
        <LocationSearch
          v-bind:locationData="locationData"
          v-bind:country="country"
        ></LocationSearch>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import firebase from "firebase/app";
require("firebase/auth");

import {
  IonPage,
  IonContent,
  IonImg,
  //IonLoading
} from "@ionic/vue";

import { ref } from "vue";
import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;
const { Geolocation } = Plugins;
import LocationSearch from "@/components/LocationSearch.vue";

export default {
  name: "Home",
  components: {
    IonContent,
    IonPage,
    LocationSearch,
    IonImg,
    // IonLoading,
  },
  setup() {
    const isOpenRef = ref(true);
    const locationData = ref({});
    const getLocation = async () => {
      const results = await Geolocation.getCurrentPosition();
      locationData.value = {
        lat: results.coords.latitude,
        long: results.coords.longitude,
      };
      Storage.set({
        key: "location",
        value: JSON.stringify(locationData.value),
      });
      Storage.set({
        key: "location_last_fetch",
        value: new Date().toISOString(),
      });
    };
    Storage.get({ key: "location_last_fetch" }).then((valueString) => {
      if (valueString.value) {
        const date = Date.parse(valueString.value);
        const diff = new Date() - date;
        const temp = true;
        if (diff > 300000 || temp) {
          getLocation();
        } else {
          Storage.get({ key: "location" })
            .then((locationString) => {
              locationData.value = JSON.parse(locationString.value);
            })
            .finally(() => {
              if (!locationData.value.lat || !locationData.value.long) {
                getLocation();
              }
            });
        }
      } else {
        getLocation();
      }
    });

    return {
      isOpenRef,
      close,
      locationData,
      getLocation,
      geocoder: new window.google.maps.Geocoder(),
      country: ref(""),
    };
  },
  computed: {
    displayName: function () {
      const displayName = firebase.auth().currentUser?.displayName;
      return displayName;
    },
  },
  methods: {
    updateCountry(addresses, status) {
      if (status !== window.google.maps.GeocoderStatus.OK) {
        this.country = "";
        return;
      }

      this.country = "";
      if (addresses.length >= 1) {
        const address = addresses[0];
        for (const addressComponent of address.address_components) {
          for (const type of addressComponent.types) {
            if (type === "country") {
              this.country = addressComponent.short_name;
              break;
            }
          }
          if (this.country.length > 0) {
            break;
          }
        }
      }
      this.isOpenRef = false;
    },
  },
  watch: {
    locationData(updated) {
      if (updated) {
        const latlong = new window.google.maps.LatLng(
          updated.lat,
          updated.long
        );
        this.geocoder.geocode(
          {
            location: latlong,
          },
          this.updateCountry
        );
      }
    },
  },
};
</script>
<style>
.black {
  color: #000;
  text-align: center;
}
h1 {
  color: #f59015;
  font-weight: 900;
}
.login-container {
  padding-top: 1em;
}
</style>