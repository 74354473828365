//
// firebaseservice - a composable for managing the interaction
// with firebase
//
// REFERENCE
// @see https://v3.vuejs.org/guide/composition-api-introduction.html#why-composition-api
//
import { onMounted, ref } from "vue";

// FIREBASE
import firebase from "firebase/app";
require("firebase/firestore")
require("firebase/auth")
require("firebase/storage")


const db = firebase.firestore();
const repo = firebase.storage();

const submissionsCollection = db.collection("submissions");

const submissions = ref<any>([]);
const error = ref<any>(null);
const loading = ref<any>(false);

export interface PhotoPath {
  url?: string;
  path: string;
  uploadTask: firebase.storage.UploadTask | null;
  progress: number;
}

export default () => {
  /**
   *
   * @param collection returns data for specified collections
   */
  const getCollectionData = async (collection: any) => {
    loading.value = true;
    try {
      // eslint-disable-next-line
      const querySnapshot = await collection.where('uid', '==', firebase.auth().currentUser!.uid).get();
      const results = querySnapshot.docs.map((doc: any) => {
        return { ...doc.data(), id: doc.id };
      });
      loading.value = false;
      return results;
    } catch (e) {
      error.value = e;
      loading.value = false;
    }
  };

  /**
   * get document from firebase and not local photos
   *
   * @param collection
   * @param id
   */
  const getCollectionDoc = async (collection: any, id: string) => {
    loading.value = true;
    try {
      const doc = await collection.doc(id).get();
      loading.value = false;
      return { ...doc.data(), id: doc.id };
    } catch (e) {
      error.value = e;
      loading.value = false;
    }
  };


  /**
   * delete document from firebase collection
   *
   * @param collection
   * @param id
   */
  const removeCollectionDoc = async (collection: any, id: string) => {
    loading.value = true;
    try {
      await collection.doc(id).delete();
      loading.value = false;
      return true
    } catch (e) {
      error.value = e;
      loading.value = false;
      return false;
    }
  };

  /**
   *
   * @param collection
   * @param data
   */
  const writeCollectionDoc = async (collection: any, data: any) => {
    loading.value = true;
    try {
      await collection.doc().set({ ...data }, { merge: true });
      loading.value = false;
      return true;
    } catch (e) {
      error.value = e;
      loading.value = false;
      return false;
    }
  };

  const loadSubmissions = async () => {
    submissions.value = await getCollectionData(submissionsCollection);
  };

  const saveSubmission = async (data: any) => {
    // eslint-disable-next-line
    data.uid = firebase.auth().currentUser!.uid;
    const toReturn = writeCollectionDoc(submissionsCollection, data);
    await loadSubmissions();
    return toReturn
  };
  const savePhoto = async (filename: string, data: string, filepath: string): Promise<PhotoPath> => {
    const metadata = {
      contentType: 'image/jpeg',
    };
    // eslint-disable-next-line
    const uid = firebase.auth().currentUser!.uid;
    const date = new Date();
    loading.value = true;
    const path = 'uploads/' + uid + "/" + date.getUTCFullYear() + "/" + (date.getUTCMonth() + 1) + "/" + date.getUTCDay() + "/" + filename;
    const ref = repo.ref().child(path);

    if (!data.startsWith('data:image/jpeg;base64,')) {
      data = 'data:image/jpeg;base64, ' + data
    }

    try {
      const uploadTask = ref.putString(data, 'data_url', metadata)

      const toReturn: PhotoPath = { path: path, uploadTask: uploadTask, url: filepath, progress: 0 }
      loading.value = false;
      return toReturn
    } catch (e) {
      error.value = e;
      loading.value = false;
      return { url: "", path: "", progress: 100, uploadTask: null };
    }
  };

  onMounted(async () => {
    await loadSubmissions();
  });

  return {
    savePhoto,
    loadSubmissions,
    saveSubmission,
    getSubmission: (id: string) => getCollectionDoc(submissionsCollection, id),
    deleteSubmittion: (id: string) => removeCollectionDoc(submissionsCollection, id),
    //properties
    submissions,
    loading,
    error,
  };
};
