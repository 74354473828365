<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            @click.prevent.stop="dismissModal(false)"
            default-href="/tabs/home"
          ></ion-back-button>
        </ion-buttons>
        <ion-title>Give Feedback For</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen="true">
      <div v-if="step == 1" style="padding: 0; margin: 0">
        <div class="location-block">
          <p>Feedback for</p>
          <p class="location-name">
            {{ place.structured_formatting.main_text }}
          </p>
          <p class="location-address">
            {{ place.structured_formatting.secondary_text }}
          </p>
        </div>
        <h2>What would you like to SUGEZT?</h2>
        <ion-list>
          <ion-item
            v-for="feedbackType in feedbackTypes"
            :key="feedbackType"
            @click="selectFeedback(feedbackType)"
          >
            <div class="feedback-type">
              <p>
                <strong>{{ feedbackType.title }}</strong> -
                <span class="light-text">{{ feedbackType.description }}</span>
              </p>
              <ion-icon :icon="chevronForwardOutline"></ion-icon>
            </div>
          </ion-item>
        </ion-list>
      </div>
      <div v-else-if="step == 2" style="padding: 0; margin: 0">
        <div class="location-block" style="padding: 0.5em 0"></div>
        <ion-item>
          <div class="feedback-type">
            <p>
              <strong>{{ selectedFeedback.title }}</strong> -
              <span class="light-text">{{ selectedFeedback.description }}</span>
            </p>
            <ion-icon :icon="chevronDownOutline"></ion-icon>
          </div>
        </ion-item>
        <ion-textarea
          required
          v-model="inputContent"
          placeholder="Share your feedback here.."
          rows="15"
        ></ion-textarea>
        <ion-grid class="photo-gallery">
          <ion-row>
            <ion-col size="6" @click="takePhoto()" class="photo-button">
              <ion-icon :icon="camera" size="large"></ion-icon>
              Add pictures to your post
            </ion-col>

            <ion-col
              size="6"
              :key="index"
              v-for="(photo, index) in galleryPhotos"
            >
              <LoadableImage
                @loading="increaseImageLoadingCount()"
                @loaded="decreaseImageLoadingCount()"
                :photo="photo"
                @click="showActionSheet(photo)"
              ></LoadableImage>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <div v-else style="padding: 0; margin: 0">
        <ion-button @click="submitFeedback" :disabled="!isSubmissionValid"
          >Submit</ion-button
        >
      </div>
    </ion-content>
    <ion-footer v-if="step == 2">
      <div class="bottom-bar">
        <div>
          <ion-toggle
            @ionChange="feedbackData.sendAnonymous = $event.target.value"
          >
          </ion-toggle>
          <ion-label style="padding-left: 1em">Send Anonymously?</ion-label>
        </div>
        <ion-button
          :disabled="!isSubmissionValid"
          color="primary"
          size="large"
          expand="full"
          @click="submitFeedback"
          >Submit</ion-button
        >
      </div>
    </ion-footer>
  </ion-page>
</template>

<script lang="ts">
export interface Photo {
  url: any;
  path: string;
}

import firebaseService, { PhotoPath } from "@/firebase-service";
import {
  IonToggle,
  IonList,
  IonItem,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  modalController,
  IonButton,
  IonIcon,
  IonTextarea,
  IonGrid,
  IonRow,
  IonCol,
  actionSheetController,
  toastController,
  IonLabel,
  IonPage,
  IonBackButton,
  IonButtons,
  IonFooter,
} from "@ionic/vue";
import { defineComponent } from "vue";
import {
  chevronForwardOutline,
  chevronBack,
  chevronDownOutline,
} from "ionicons/icons";
import { camera, trash, close } from "ionicons/icons";

import { usePhotoGallery } from "@/composables/usePhotoGallery";
import LoadableImage from "@/components/LoadableImage.vue";

export default defineComponent({
  name: "Submission Modal",
  props: {
    place: { type: Object },
  },
  components: {
    IonToggle,
    IonList,
    IonItem,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButton,
    IonIcon,
    IonTextarea,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonPage,
    IonBackButton,
    IonButtons,
    IonFooter,
    LoadableImage,
  },
  data() {
    return {
      imageLoadingCount: 0,
      inputContent: "",
      step: 1,
      feedbackTypes: [
        {
          title: "Inspire",
          description:
            "Share an idea or an innovative suggestion that doesn't currently exist.",
        },
        {
          title: "Enhance",
          description:
            "Provide feedback to improve and existing product, service or experience.",
        },
        {
          title: "Critique",
          description:
            "Share a challenge or problem you encountered that you would like to see resolved.",
        },
        {
          title: "Praise",
          description:
            "Praise for great service, experience, or a job well done.",
        },
      ],
      selectedFeedback: null,
    };
  },
  setup() {
    const { saveSubmission } = firebaseService();
    const { takePhoto, deleteFromGallery, galleryPhotos } = usePhotoGallery();
    const showActionSheet = async (photo: PhotoPath) => {
      const actionSheet = await actionSheetController.create({
        header: "Photo",
        cssClass: "action-sheet",
        buttons: [
          {
            text: "Delete",
            role: "destructive",
            icon: trash,
            handler: () => {
              // eslint-disable-next-line
              deleteFromGallery(photo.url!);
            },
          },
          {
            text: "Cancel",
            icon: close,
            role: "cancel",
            handler: () => {
              // Nothing to do, action sheet is automatically closed
            },
          },
        ],
      });
      await actionSheet.present();
    };
    const feedbackData = {
      place: { name: "", id: null },
      type: "",
      content: "",
      sendAnonymous: false,
      date: new Date().toDateString(),
      datetime: new Date().toISOString(),
      photos: Array<Photo>(),
    };

    feedbackData.date = feedbackData.date.substring(
      feedbackData.date.indexOf(" ") + 1
    );

    return {
      chevronForwardOutline,
      chevronBack,
      takePhoto,
      camera,
      trash,
      deleteFromGallery,
      showActionSheet,
      saveSubmission,
      chevronDownOutline,
      feedbackData,
      galleryPhotos,
    };
  },
  methods: {
    increaseImageLoadingCount() {
      this.imageLoadingCount++;
    },
    decreaseImageLoadingCount() {
      this.imageLoadingCount--;
    },
    dismissModal(success: boolean) {
      const response = {
        success: success,
      };
      modalController.dismiss(response);
    },
    selectFeedback(feedbackType: any) {
      this.feedbackData.type = feedbackType.title;
      // eslint-disable-next-line
      this.feedbackData.place.id = this.place!.place_id;
      // eslint-disable-next-line
      this.feedbackData.place.name = this.place!.structured_formatting.main_text;
      this.selectedFeedback = feedbackType;
      this.step++;
    },
    completedContent() {
      this.step++;
    },
    submitFeedback() {
      //Let's check the feedback
      if (this.inputContent.length == 0) {
        return toastController
          .create({
            message: "Please enter some feedback before submitting",
            duration: 2000,
            cssClass: "toast-center-text",
          })
          .then((toast) => toast.present());
      }
      //clear it out
      this.feedbackData.content = this.inputContent;
      this.feedbackData.photos = [];
      for (let index = 0; index < this.galleryPhotos.length; index++) {
        if (this.galleryPhotos[index].progress != 100) {
          return toastController
            .create({
              message: "Please wait until all images are uploaded",
              duration: 2000,
              cssClass: "toast-center-text",
            })
            .then((toast) => toast.present());
        }
        this.feedbackData.photos.push({
          url: this.galleryPhotos[index].url,
          path: this.galleryPhotos[index].path,
        });
      }

      // console.log(this.feedbackData);
      this.saveSubmission(this.feedbackData);

      return toastController
        .create({
          message: "Your SUGEZTion has been sent! Thank you",
          duration: 2000,
          position: "middle",
          translucent: false,
          cssClass: "toast-center-text",
        })
        .then((toast) => toast.present())
        .finally(() => this.dismissModal(true));
    },
  },
  computed: {
    isSubmissionValid() {
      if (this.inputContent.length == 0 || this.imageLoadingCount > 0) {
        return false;
      }

      return true;
    },
  },
});
</script>

<style scoped>
h2 {
  color: #f59015;
  font-weight: 900;
  text-align: center;
  padding: 1em 0;
}

ion-content,
ion-footer {
  --background: #efeff4 !important;
  --color: #000 !important;
}

.location-block {
  background: linear-gradient(
    130deg,
    rgba(83, 168, 186, 1) 20%,
    rgba(118, 204, 222, 1) 90%
  );
  color: #fff;
  padding: 2em;
  margin: 0;
  text-transform: uppercase;
}
.location-block p {
  margin: 0;
  margin-bottom: 0.5em;
}

.location-name {
  font-size: 150%;
  font-weight: bold;
}
.location-address {
  text-transform: none;
}
.light-text {
  opacity: 0.6;
}

.feedback-type {
  display: flex;
  align-items: center;
  padding: 0.5em;
  width: 100%;
}
.feedback-type p {
  flex: 1;
}
.feedback-type ion-icon {
  flex-basis: 10%;
}
.photo-gallery {
  background-color: #fff;
  --background: #fff;
  border-top: 1px solid #aaa;
}
ion-col {
  align-items: center;
  justify-content: center;
  min-height: 10em;
  text-align: center;
}
.photo-button {
  border: 1px solid #333;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.photo-button ion-icon {
  font-size: 4em;
}
.bottom-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 0.5em;
  border-top: 1px solid #333;
}
.bottom-bar ion-button {
  width: 100%;
  flex: 1;
}
.bottom-bar div {
  flex: 1;
  display: flex;
  align-items: center;
}
</style>