
export interface Photo {
  url: any;
  path: string;
}

import firebaseService, { PhotoPath } from "@/firebase-service";
import {
  IonToggle,
  IonList,
  IonItem,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  modalController,
  IonButton,
  IonIcon,
  IonTextarea,
  IonGrid,
  IonRow,
  IonCol,
  actionSheetController,
  toastController,
  IonLabel,
  IonPage,
  IonBackButton,
  IonButtons,
  IonFooter,
} from "@ionic/vue";
import { defineComponent } from "vue";
import {
  chevronForwardOutline,
  chevronBack,
  chevronDownOutline,
} from "ionicons/icons";
import { camera, trash, close } from "ionicons/icons";

import { usePhotoGallery } from "@/composables/usePhotoGallery";
import LoadableImage from "@/components/LoadableImage.vue";

export default defineComponent({
  name: "Submission Modal",
  props: {
    place: { type: Object },
  },
  components: {
    IonToggle,
    IonList,
    IonItem,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButton,
    IonIcon,
    IonTextarea,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonPage,
    IonBackButton,
    IonButtons,
    IonFooter,
    LoadableImage,
  },
  data() {
    return {
      imageLoadingCount: 0,
      inputContent: "",
      step: 1,
      feedbackTypes: [
        {
          title: "Inspire",
          description:
            "Share an idea or an innovative suggestion that doesn't currently exist.",
        },
        {
          title: "Enhance",
          description:
            "Provide feedback to improve and existing product, service or experience.",
        },
        {
          title: "Critique",
          description:
            "Share a challenge or problem you encountered that you would like to see resolved.",
        },
        {
          title: "Praise",
          description:
            "Praise for great service, experience, or a job well done.",
        },
      ],
      selectedFeedback: null,
    };
  },
  setup() {
    const { saveSubmission } = firebaseService();
    const { takePhoto, deleteFromGallery, galleryPhotos } = usePhotoGallery();
    const showActionSheet = async (photo: PhotoPath) => {
      const actionSheet = await actionSheetController.create({
        header: "Photo",
        cssClass: "action-sheet",
        buttons: [
          {
            text: "Delete",
            role: "destructive",
            icon: trash,
            handler: () => {
              // eslint-disable-next-line
              deleteFromGallery(photo.url!);
            },
          },
          {
            text: "Cancel",
            icon: close,
            role: "cancel",
            handler: () => {
              // Nothing to do, action sheet is automatically closed
            },
          },
        ],
      });
      await actionSheet.present();
    };
    const feedbackData = {
      place: { name: "", id: null },
      type: "",
      content: "",
      sendAnonymous: false,
      date: new Date().toDateString(),
      datetime: new Date().toISOString(),
      photos: Array<Photo>(),
    };

    feedbackData.date = feedbackData.date.substring(
      feedbackData.date.indexOf(" ") + 1
    );

    return {
      chevronForwardOutline,
      chevronBack,
      takePhoto,
      camera,
      trash,
      deleteFromGallery,
      showActionSheet,
      saveSubmission,
      chevronDownOutline,
      feedbackData,
      galleryPhotos,
    };
  },
  methods: {
    increaseImageLoadingCount() {
      this.imageLoadingCount++;
    },
    decreaseImageLoadingCount() {
      this.imageLoadingCount--;
    },
    dismissModal(success: boolean) {
      const response = {
        success: success,
      };
      modalController.dismiss(response);
    },
    selectFeedback(feedbackType: any) {
      this.feedbackData.type = feedbackType.title;
      // eslint-disable-next-line
      this.feedbackData.place.id = this.place!.place_id;
      // eslint-disable-next-line
      this.feedbackData.place.name = this.place!.structured_formatting.main_text;
      this.selectedFeedback = feedbackType;
      this.step++;
    },
    completedContent() {
      this.step++;
    },
    submitFeedback() {
      //Let's check the feedback
      if (this.inputContent.length == 0) {
        return toastController
          .create({
            message: "Please enter some feedback before submitting",
            duration: 2000,
            cssClass: "toast-center-text",
          })
          .then((toast) => toast.present());
      }
      //clear it out
      this.feedbackData.content = this.inputContent;
      this.feedbackData.photos = [];
      for (let index = 0; index < this.galleryPhotos.length; index++) {
        if (this.galleryPhotos[index].progress != 100) {
          return toastController
            .create({
              message: "Please wait until all images are uploaded",
              duration: 2000,
              cssClass: "toast-center-text",
            })
            .then((toast) => toast.present());
        }
        this.feedbackData.photos.push({
          url: this.galleryPhotos[index].url,
          path: this.galleryPhotos[index].path,
        });
      }

      // console.log(this.feedbackData);
      this.saveSubmission(this.feedbackData);

      return toastController
        .create({
          message: "Your SUGEZTion has been sent! Thank you",
          duration: 2000,
          position: "middle",
          translucent: false,
          cssClass: "toast-center-text",
        })
        .then((toast) => toast.present())
        .finally(() => this.dismissModal(true));
    },
  },
  computed: {
    isSubmissionValid() {
      if (this.inputContent.length == 0 || this.imageLoadingCount > 0) {
        return false;
      }

      return true;
    },
  },
});
