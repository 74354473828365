
<template>
  <ion-searchbar
    v-model="location"
    placeholder="Enter a provider name"
  ></ion-searchbar>

  <ion-grid>
    <div v-if="searchResults.length == 0" style="margin: 0; padding: 0">
      <ion-row>
        <div class="search-result">
          <div class="search-result-name-group">
            <p class="search-result-name">Loading Results</p>
            <p class="search-result-location">
              Please make sure to enable location services for nearby results.
            </p>
          </div>
          <div class="search-result-distance">
            <ion-spinner name="crescent"></ion-spinner>
          </div>
        </div>
      </ion-row>
    </div>
    <div v-else style="margin: 0; padding: 0">
      <ion-row
        :key="place"
        v-for="place in searchResults"
        @click="triggerSubmission(place)"
      >
        <div class="search-result">
          <div class="search-result-name-group">
            <p class="search-result-name">
              {{ place.structured_formatting.main_text }}
            </p>
            <p class="search-result-location">
              {{ place.structured_formatting.secondary_text }}
            </p>
          </div>
          <div class="search-result-distance">
            <span>{{ formatDistance(place.distance_meters) }}</span>
            {{ unitOfMeasure }} away
          </div>
        </div>
      </ion-row>
    </div>
  </ion-grid>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from "vue";

import {
  IonSearchbar,
  IonGrid,
  IonRow,
  modalController,
  IonSpinner
} from "@ionic/vue";
import captureSubmissionModal from "@/components/captureSubmissionModal";

export default defineComponent({
  name: "LocationSearch",
  props: {
    locationData: Object,
    country: String,
  },
  components: {
    IonSearchbar,
    IonGrid,
    IonRow,
    IonSpinner
  },
  setup() {
    const service = new window.google.maps.places.AutocompleteService();
    const placesService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    const matrixService = new window.google.maps.DistanceMatrixService();
    return {
      service,
      placesService,
      matrixService,
    };
  },
  data() {
    return {
      location: "",
      searchResults: [],
      nearbyResults: [],
    };
  },
  computed: {
    unitOfMeasure: function () {
      return this.country == "US" ? "miles" : "km";
    },
  },
  created() {
    this.initialNearbySearch();
  },
  methods: {
    formatDistance(distance) {
      const value = distance;
      if (this.country == "US") {
        return (value * 0.00062137).toFixed(1);
      } else {
        return (value / 1000).toFixed(1);
      }
    },
    initialNearbySearch() {
      if (this.nearbyResults.length == 0) {
        // eslint-disable-next-line
        const latlong = new window.google.maps.LatLng(
          // eslint-disable-next-line
          this.locationData.lat,
          // eslint-disable-next-line
          this.locationData.long
        );
        this.placesService.nearbySearch(
          {
            location: latlong,
            type: "hospital",
            radius: 30000,
          },
          (results, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              this.nearbyResults = results.map((place) => {
                this.matrixService.getDistanceMatrix(
                  {
                    origins: [latlong],
                    destinations: [place.geometry.location],
                    travelMode: window.google.maps.TravelMode.DRIVING,
                    unitSystem: window.google.maps.UnitSystem.METRIC,
                    avoidHighways: false,
                    avoidTolls: false,
                  },
                  (response, status) => {
                    if (status == "OK") {
                      for (const val of this.nearbyResults) {
                        if (val["place_id"] === place.place_id) {
                          val.distance_meters =
                            response.rows[0].elements[0].distance.value;
                        }
                      }
                      this.nearbyResults.sort(
                        (n1, n2) =>
                          n1.distance_meters - n2.distance_meters
                      );
                    }
                  }
                );
                return {
                  place_id: place.place_id,
                  distance_meters: "",
                  structured_formatting: {
                    main_text: place.name,
                    secondary_text: place.vicinity,
                  },
                };
              });

              this.searchResults = this.nearbyResults;
            }
          }
        );
      } else {
        this.searchResults = this.nearbyResults;
      }
    },
    async triggerSubmission(place) {
      const modal = await modalController.create({
        component: captureSubmissionModal,
        componentProps: {
          place: place,
        },
      });
      return modal.present();
    },
    displaySuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = [];
        return;
      }
      this.searchResults = predictions.filter((prediction) => {
        if (!prediction) return false;
        return (
          prediction.types.includes("hospital") ||
          prediction.types.includes("health")
        );
      });
    },
  },
  watch: {
    locationData(updated) {
      if (updated) {
        this.initialNearbySearch();
      }
    },
    location(newValue) {
      if (newValue && newValue.length > 0) {
        if (this.service) {
          if (this.locationData) {
            const latlong = new window.google.maps.LatLng(
              this.locationData.lat,
              this.locationData.long
            );
            this.service.getPlacePredictions(
              {
                input: newValue,
                types: ["establishment"],
                componentRestrictions: { country: this.country },
                location: latlong,
                origin: latlong,
                radius: 5000,
              },
              this.displaySuggestions
            );
          }
        }
      } else {
        this.searchResults = this.nearbyResults;
      }
    },
  },
});
</script>

<style scoped>
ion-grid {
  width: 90%;
  max-width: 600px;
}
ion-row {
  width: 100%;
  border-radius: 0.5em;
  background-color: #fff;
  --background: #fff;
  margin-bottom: 0.5em;
  color: #000;
}
.search-result {
  display: flex;
  width: 100%;
}
.search-result-name-group {
  padding: 1em;
  flex: 1;
  flex-basis: 60%;
}
.search-result-name {
  font-weight: bold;
  padding: 0;
  margin: 0;
}
.search-result-location {
  padding: 0;
  padding-top: 0.5em;
  margin: 0;
}
.search-result-distance {
  padding: 1em;
  background-color: #f59015;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  flex-basis: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.search-result-distance span {
  font-size: 200%;
  display: block;
  font-weight: bold;
}
</style>